import {
  mannequin1,
  mannequin2,
  mannequin3,
  mannequin4,
  mannequin5,
} from "../../assets";

export const newArrivalData = [
  {
    id: 101,
    title: "Peppermint Mocha",
    price: "4.35",
    quantity: 1
  },
  {
    id: 102,
    title: "Summer Berry Lemonade",
    price: "3.45",
    quantity: 1
  },
  {
    id: 103,
    title: "Caramel Latte",
    price: "4.25",
    quantity: 1
  },
  {
    id: 104,
    title: "Irish Cream Cold Brew",
    price: "3.99",
    quantity: 1
  },
  {
    id: 105,
    title: "Pumpkin Spice Latte",
    price: "4.99",
    quantity: 1
  },
  {
    id: 106,
    title: "Lavender Latte",
    price: "3.49",
    quantity: 1
  },
];
