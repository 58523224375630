import {
  mannequin1,
  mannequin2,
  mannequin3,
  mannequin4,
  mannequin5,
} from "../../assets";

export const productData = [
  {
    id: 1,
    title: "Vanilla Latte",
    price: "3.35",
    quantity: 1,
  },
  {
    id: 2,
    title: "Hot Chocolate",
    price: "2.25",
    quantity: 1,
  },
  {
    id: 3,
    title: "Caramel Frappuccino",
    price: "5.25",
    quantity: 1,
  },
  {
    id: 4,
    title: "Chai Tea Latte",
    price: "3.85",
    quantity: 1,
  },
  {
    id: 5,
    title: "Cold Brew",
    price: "2.85",
    quantity: 1,
  },
  {
    id: 6,
    title: "Espresso",
    price: "2.85",
    quantity: 1,
  },
];
