import React from "react";
import { Button } from "antd";
import "./Banner.css";
import { useNavigate } from "react-router";
import LoginExperiences from "../loginExperiences/LoginExperiences";
import { useSession } from '@descope/react-sdk'

function Banner() {
  const navigate = useNavigate();
  const { isAuthenticated } = useSession();
  return (
    <>
      <div className="first-screen">
        <div className="container-col-2">
          <div className="container-section-1">
            <h1 className="hero-heading">Discover a drink that's made for you!</h1>
            <p className="hero-para">
              The Happiest hour of the year!
            </p>
            {
              isAuthenticated ?
                <div
                  className="sign-button"
                  style={{ backgroundColor: '#FFFFFF' }}
                >
                </div>
                :
                <Button
                  className="sign-button"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
            }
          </div>
          {/* for image  */}
          <div className="container-sec-2">
            <img
              src={"https://hips.hearstapps.com/hmg-prod/images/psl-the-best-starbucks-drinks-country-living-65fc5334eb1e5.jpg"}
              alt="bg-img"
            />
            
          </div>
        </div>
      </div>
      <LoginExperiences />
    </>
  );
}

export default Banner;
